import {WebBlog} from '@betofumi/common/types/dataTypes';
import {useState} from 'react';
import {useAsyncEffect} from 'use-async-effect';
import {useGlobal} from '../context/globalContext';
import api from '../utils/api';
import Breadcrumb from './Breadcrumb';
import {NewsItem} from './home/HomeBlog';

export default function News() {
  const [news, setNews] = useState<WebBlog[]>([]);
  const {trans} = useGlobal();

  useAsyncEffect(async () => {
    const news = await api.get<WebBlog[]>({path: 'web/news'});
    setNews(news);
  }, []);

  return (
    <>
      <Breadcrumb name={trans('NEWS')} />

      <section className="tf__blog_page mt_75 xs_mt_45 mb_100 xs_mb_70">
        <div className="container">
          <div className="row">
            {news.map((item) => (
              <NewsItem key={item.id} item={item} home={false} />
            ))}
          </div>
          {/*
          <div className="tf__pagination mt_50">
            <div className="row">
              <div className="col-12">
                <nav aria-label="...">
                  <ul className="pagination">
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <i className="fas fa-long-arrow-alt-left"></i>
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        1
                      </a>
                    </li>
                    <li className="page-item active">
                      <a className="page-link" href="#">
                        2
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        3
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <i className="fas fa-long-arrow-alt-right"></i>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          */}
        </div>
      </section>
    </>
  );
}
