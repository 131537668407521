import {DishInfoDetails} from '@betofumi/common/types/dataTypes';
import {useState} from 'react';
import Slider from 'react-slick';
import {useAsyncEffect} from 'use-async-effect';
import {useGlobal} from '../../context/globalContext';
import api from '../../utils/api';
import {getCurrentDetails} from '../../utils/misc';
import {SampleNextArrow, SamplePrevArrow} from './HomeBlog';

const options = {
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 4000,
  dots: false,
  arrows: true,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1
      }
    },
    {
      breakpoint: 576,
      settings: {
        arrows: false,
        slidesToShow: 1
      }
    }
  ]
};

export default function Offer() {
  const {language, trans} = useGlobal();
  const [dishes, setDishes] = useState<DishInfoDetails[]>([]);

  const getDishes = async () => {
    const dishes: DishInfoDetails[] = await api.get({path: 'dish'});
    setDishes(dishes);
    setTimeout(() => {
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const wow = new window.WOW();
        wow.init();
      } catch (e) {
        console.log(e);
      }
    }, 1000);
  };

  useAsyncEffect(getDishes, []);

  return (
    <section className="tf__offer_item pt_95 pb_100 xs_pt_65 xs_pb_70">
      <div className="container">
        <div className="row wow fadeInUp" data-wow-duration="1s">
          <div className="col-md-8 col-lg-7 col-xl-6">
            <div className="tf__section_heading mb_25">
              <h4>{trans('FEATURE_DISHES')}</h4>
              <h2>{trans('FEATURE_FOODS_MENU')}</h2>
            </div>
          </div>
        </div>
        <div className="row offer_item_slider wow flex fadeInUp" data-wow-duration="1s">
          <Slider {...options}>
            {dishes
              .filter((d) => d.web === 1)
              .map((dish) => {
                if (dish.id > 1000) {
                  return null;
                }

                const details = getCurrentDetails(dish.details, language);
                return (
                  <div key={dish.id} className="col-xl-4">
                    <div className="tf__offer_item_single" style={{background: `url(${dish.photos})`}}>
                      <div className="tf__offer_item_single_inner">
                        <span className="rotate-45">New!!!</span>
                        <a className="title" href="/">
                          {details?.title}
                        </a>
                        <p>{details?.description}</p>
                        <ul className="d-flex flex-wrap">
                          <li>
                            <a href="/" data-bs-toggle="modal" data-bs-target="#cartModal">
                              <i className="fas fa-shopping-basket"></i>
                            </a>
                          </li>
                          <li>
                            <a href="/">
                              <i className="fal fa-heart"></i>
                            </a>
                          </li>
                          <li>
                            <a href="/">
                              <i className="far fa-eye"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                );
              })}
          </Slider>
        </div>
      </div>
    </section>
  );
}
