import {Link} from 'react-router-dom';
import {useGlobal} from '../context/globalContext';

type LinkGroup = Array<{title: string; links: Array<{text: string; url: string}>}>;

export default function Footer() {
  const {info, trans, translation} = useGlobal();
  const linkGroups: LinkGroup = [
    {
      title: 'Short links',
      links: [
        {text: 'Home', url: '/'},
        {text: 'About us', url: '/about-us'},
        {text: 'Contact us', url: '/contact'},
        {text: 'Reservation', url: '/yoyaku.html'}
      ]
    },
    {
      title: 'Help links',
      links: [
        {text: 'TERMS_CONDITIONS', url: '/'},
        {text: 'Privacy Policy', url: '/about-us'},
        {text: 'FAQ', url: '/contact'}
      ]
    }
  ];

  return (
    <>
      <footer style={{background: `url(images/footer_bg.jpg)`}}>
        <div className="footer_overlay pt_100 xs_pt_70 pb_100 xs_pb_20">
          <div className="container wow fadeInUp" data-wow-duration="1s">
            <div className="row justify-content-between">
              <div className="col-xxl-4 col-lg-4 col-sm-9 col-md-7">
                <div className="tf__footer_content">
                  <a className="footer_logo" href="index.html">
                    <img src="images/logo_white.svg" alt="RegFood" className="img-fluid w-100" />
                  </a>
                  <span>{translation.description}</span>
                  <ul className="social_link d-flex flex-wrap">
                    <li>
                      <a target="_blank" rel="noreferrer" href={info?.facebook as string}>
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a target="_blank" rel="noreferrer" href={info?.instagram as string}>
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a target="_blank" rel="noreferrer" href={info?.tiktok as string}>
                        <i className="fab fa-tiktok"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              {linkGroups.map((group) => {
                return (
                  <div key={group.title} className="col-xxl-2 col-lg-2 col-sm-5 col-md-5">
                    <div className="tf__footer_content">
                      <h3>{trans(group.title)}</h3>
                      <ul>
                        {group.links.map((link) => {
                          return (
                            <li key={link.url}>
                              <Link to={link.url}>{trans(link.text)}</Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                );
              })}

              <div className="col-xxl-3 col-lg-4 col-sm-9 col-md-7 order-lg-4">
                <div className="tf__footer_content">
                  <h3>{trans('contact us')}</h3>
                  <p className="info">
                    <i className="fas fa-phone-alt"></i> {info?.phone}
                  </p>
                  <p className="info">
                    <i className="fas fa-envelope"></i> {info?.email}
                  </p>
                  <p className="info">
                    <i className="fa fa-map-marker-alt"></i> {translation.address}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tf__footer_bottom d-flex flex-wrap">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="tf__footer_bottom_text">
                  <p>
                    Copyright ©<b> Betofumi</b> 2023. All Rights Reserved
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="tf__scroll_btn">
        <i className="fas fa-hand-pointer"></i>
      </div>
    </>
  );
}
