export default function BannerIcons() {
  return (
    <>
      <span className="banner_shape banner_shape_1">
        <img src="images/tree_5.png" alt="shape" className="img-fluid w-100" />
      </span>
      <span className="banner_shape banner_shape_2">
        <img src="images/tree_3.png" alt="shape" className="img-fluid w-100" />
      </span>
      <span className="banner_shape banner_shape_3">
        <img src="images/tree_4.png" alt="shape" className="img-fluid w-100" />
      </span>
      <span className="banner_shape banner_shape_4">
        <img src="images/tree_6.png" alt="shape" className="img-fluid w-100" />
      </span>
      <span className="banner_shape banner_shape_5">
        <img src="images/tree_7.png" alt="shape" className="img-fluid w-100" />
      </span>
      <span className="banner_shape banner_shape_6">
        <img src="images/tree_2.png" alt="shape" className="img-fluid w-100" />
      </span>
    </>
  );
}
