import {useGlobal} from '../context/globalContext';

export default function TopBar() {
  const {info} = useGlobal();
  return (
    <section className="tf__topbar">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-sm-6 col-md-8">
            <ul className="tf__topbar_info d-flex flex-wrap d-none d-sm-flex">
              <li>
                <a href="mailto:example@gmail.com">
                  <i className="fas fa-envelope"></i> {info?.email}
                </a>
              </li>
              <li className="d-none d-md-block">
                <a href={`callto:${info?.phone}`}>
                  <i className="fas fa-phone-alt"></i>
                  {info?.phone}
                </a>
              </li>
            </ul>
          </div>
          <div className="col-xl-6 col-sm-6 col-md-4">
            <ul className="topbar_icon d-flex flex-wrap">
              <li>
                <a target="_blank" rel="noreferrer" href={info?.facebook as string}>
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a target="_blank" rel="noreferrer" href={info?.instagram as string}>
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
              <li>
                <a target="_blank" rel="noreferrer" href={info?.tiktok as string}>
                  <i className="fab fa-tiktok"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
