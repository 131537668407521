import {useGlobal} from '../context/globalContext';
import Breadcrumb from './Breadcrumb';

export default function Menu() {
  const {trans} = useGlobal();

  return (
    <>
      <Breadcrumb name={trans('Menu')} />

      <section className="tf__menu_page mt_100 xs_mt_70 mb_100 xs_mb_70">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-sm-6 col-lg-4 wow fadeInUp" data-wow-duration="1s">
              <div className="tf__menu_item">
                <div className="tf__menu_item_img">
                  <img src="images/menu2_img_2.jpg" alt="menu" className="img-fluid w-100" />
                </div>
                <div className="tf__menu_item_text">
                  <a className="category" href="#">
                    Chicken
                  </a>
                  <a className="title" href="menu_details.html">
                    Daria Shevtsova
                  </a>
                  <p className="rating">
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <span>30</span>
                  </p>
                  <h5 className="price">$80.00</h5>
                  <a className="tf__add_to_cart" href="#" data-bs-toggle="modal" data-bs-target="#cartModal">
                    add to cart
                  </a>
                  <ul className="d-flex flex-wrap justify-content-end">
                    <li>
                      <a href="#">
                        <i className="fal fa-heart"></i>
                      </a>
                    </li>
                    <li>
                      <a href="menu_details.html">
                        <i className="far fa-eye"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
