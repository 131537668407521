import React, {useEffect} from 'react';
import {useGlobal} from '../../context/globalContext';
import BannerIcons from './BannerIcons';

const Banner = () => {
  const {slides} = useGlobal();

  useEffect(() => {
    const images = document.getElementsByClassName('banner-slide');
    let currentIndex = 0;
    const slide = () => {
      for (const [index, image] of [...images].entries()) {
        if (index !== currentIndex) {
          image.classList.add('hidden');
        } else {
          image.classList.remove('hidden');
        }
      }

      if (currentIndex === slides.length - 1) {
        currentIndex = 0;
      } else {
        currentIndex++;
      }
    };

    slide();
    const interval = setInterval(slide, 5000);

    return () => clearInterval(interval);
  }, [slides.length]);

  return (
    <section className="tf__banner">
      <div className="tf__banner_overlay">
        <BannerIcons />
        <div className="col-12">
          {slides.map((slide) => {
            return (
              <div key={slide.id} className="tf__banner_slider banner-slide" style={{background: `url(${slide.url})`}}>
                <div className="tf__banner_slider_overlay">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xxl-6 col-xl-6 col-md-10 col-lg-6">
                        <div className="tf__banner_text wow fadeInLeft" data-wow-duration="1s">
                          <h3>{slide.tag}</h3>
                          <h1>{slide.title}</h1>
                          <p>{slide.description}</p>
                          {/*<form>*/}
                          {/*  <input type="text" placeholder="Search . . ." />*/}
                          {/*  <button type="submit" className="common_btn">*/}
                          {/*    search*/}
                          {/*  </button>*/}
                          {/*</form>*/}
                        </div>
                      </div>

                      <div className="col-xxl-5 col-xl-6 col-sm-10 col-md-9 col-lg-6">
                        <div className="tf__banner_img wow fadeInRight" data-wow-duration="1s">
                          <div className="img">
                            <img src={slide.url2} alt="food item" className="img-fluid w-100" />
                            {/*<span>35% off</span>*/}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Banner;
