export const IS_MAC = typeof window !== 'undefined' && /Mac|iPod|iPhone|iPad/.test(window.navigator.platform);

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export function noop(): void {
  return;
}

export function isJSONResponse(res: Response): boolean {
  for (const [header, value] of res.headers.entries()) {
    if (header.toLowerCase() === 'content-type' && value.toLowerCase().includes('application/json')) {
      return true;
    }
  }

  return false;
}

export enum BrowserName {
  EDGE = 'Edge',
  CHROMIUM_BASE_EDGE = 'Microsoft Edge',
  OPERA = 'Opera',
  CHROME = 'Google Chrome',
  IE = 'Internet Explorer',
  FIREFOX = 'Mozilla Firefox',
  SAFARI = 'Safari',
  COC_COC = 'CocCoc',
  OTHER = 'other'
}

export function getBrowserName(): BrowserName {
  const userAgent = navigator.userAgent.toLowerCase();
  switch (true) {
    case userAgent.includes('edge'):
      return BrowserName.EDGE;
    case userAgent.includes('edg/'):
      return BrowserName.CHROMIUM_BASE_EDGE; // Match also / to avoid matching for the older Edge
    case userAgent.includes('safari') && !userAgent.includes('chrome') && !userAgent.includes('crios'):
      return BrowserName.SAFARI;
    case userAgent.includes('coc_coc_browser'):
      return BrowserName.COC_COC;
    case userAgent.includes('opr'):
      return BrowserName.OPERA;
    case userAgent.includes('trident'):
      return BrowserName.IE;
    case userAgent.includes('firefox'):
      return BrowserName.FIREFOX;
    case userAgent.includes('chrome'):
    case userAgent.includes('crios'):
      return BrowserName.CHROME;
    default:
      return BrowserName.OTHER;
  }
}

export const createThrottler = <T, U>(
  applyData: (value: T, currentValue: T) => T,
  execute: (data: T) => U
): ((value: T, throttle: number) => void) => {
  let data: T | undefined = undefined;
  return (value: T, throttle: number) => {
    if (data != null) {
      data = applyData(value, data);
    } else {
      data = value;
      setTimeout(() => {
        if (data) {
          execute(data);
          data = undefined;
        }
      }, throttle);
    }
  };
};

export function playBeep(): void {
  const AudioContext = window.AudioContext || window.webkitAudioContext;
  const audioCtx = new AudioContext();

  // Create an oscillator node
  const oscillator = audioCtx.createOscillator();

  // Set the frequency and type of the oscillator
  oscillator.frequency.setValueAtTime(1000, audioCtx.currentTime);
  oscillator.type = 'square';

  // Connect the oscillator to the audio context destination
  oscillator.connect(audioCtx.destination);

  // Start the oscillator after a 5-second delay
  setTimeout(() => {
    oscillator.start();
    // Stop the oscillator after 0.5 seconds
    setTimeout(() => {
      oscillator.stop();
    }, 500);
  }, 5000);
}

export async function playHarmony() {
  const context = new AudioContext();
  const notes = [
    349.23, // F4
    261.63, // C4
    349.23, // F4
    261.63 // C4
  ];

  const duration = 0.2; // duration of each note in seconds

  for (const [index, note] of notes.entries()) {
    const oscillator = context.createOscillator();
    oscillator.type = 'sine';
    oscillator.frequency.setValueAtTime(note, context.currentTime);
    oscillator.connect(context.destination);
    oscillator.start(index * duration);
    oscillator.stop((index + 1) * duration);
  }
}

type Details = {id: string; code: string; name: string; title: string; description: string} | undefined;
export function getCurrentDetails(detailStr: string, language: string) {
  const detail: Details[] = detailStr.split('|||||').map((lang) => {
    const [id, code, name, title, description] = lang.split(':::::');
    return {id, code, name, title, description};
  });
  return detail.find((d) => d?.code === language);
}
