/* eslint-disable */
import trim from 'lodash/trim';
import {v4} from 'uuid';

export function isEmpty(value: string | undefined | null): boolean {
  return value == null || value === '';
}

export function isNotEmpty(value: string | undefined | null): value is string {
  return !isEmpty(value);
}

export function isEqual(
  stringA: string | undefined | null,
  stringB: string | undefined | null,
  ignoreCase = false
): boolean {
  if (ignoreCase && stringA != null && stringB != null) {
    return stringA.toLowerCase() === stringB.toLowerCase();
  }

  return stringA === stringB;
}

export function convertToUnicode(input: string): string {
  const charcode = parseInt(input.replace('\\u', ''), 16);
  return String.fromCharCode(charcode);
}

export function capitalize(input: string): string {
  input = input.charAt(0).toUpperCase() + input.slice(1);
  return input;
}

export function getFileNameFromURL(url: string): string {
  const urlObject = new URL(url, location.origin);
  const splitPathName = urlObject.pathname.split('/');
  return splitPathName[splitPathName.length - 1];
}

export function validateEmail(email: string): boolean {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
}

export function trimNewlines(string: string): string {
  return string.replace(/\r?\n|\r/gm, '');
}

export function spliceString(string: string, start: number, end: number, insert: string): string {
  return string.substring(0, start) + insert + string.substring(end);
}

export function randomKeyString(): string {
  return v4();
}

export {trim};

export function toTitleCase(string: string): string {
  return string.charAt(0).toUpperCase() + string.toLowerCase().substring(1);
}


export function getFirstCharOfWords(text: string): string {
  return text
    .split(' ')
    .map((word) => word.charAt(0))
    .join('')
    .toLowerCase();
}

export function removeNonDigits(string: string): string {
  return string.replace(/\D/g, '');
}

export function isGreaterSize(sizeA?: string, sizeB?: string): boolean {
  if (sizeA == null) {
    return false;
  }

  if (sizeB == null) {
    return true;
  }

  const numberA = parseInt(removeNonDigits(sizeA));
  const numberB = parseInt(removeNonDigits(sizeB));

  return numberA > numberB;
}

export function getMaxSize(sizes: string[]): string {
  let maxSize = sizes[0];
  for (let i = 1; i < sizes.length; i++) {
    if (isGreaterSize(sizes[i], maxSize)) {
      maxSize = sizes[i];
    }
  }

  return maxSize;
}

/**
 * Split large string in n-size chunks
 * @param text: string
 * @param size: number
 */
export function chunkSubstr(text: string, size: number): string[] {
  const numberChunks = Math.ceil(text.length / size);
  const chunks = new Array(numberChunks);

  for (let i = 0, o = 0; i < numberChunks; ++i, o += size) {
    chunks[i] = text.substr(o, size).trim();
  }

  return chunks;
}

export function formatNumber(num: number, decimalPlaces: number = 0): string {
  // Round the number to the specified number of decimal places
  const roundedNum = Math.round(num * 10 ** decimalPlaces) / 10 ** decimalPlaces;

  // Convert the number to a string and split it into integer and decimal parts
  const [integerPart, decimalPart] = roundedNum.toFixed(decimalPlaces).split('.');

  // Add commas to the integer part of the number
  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Combine the integer and decimal parts and return the formatted string
  return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
}

export function padWithZeroes(number: number, length: number) {

  let myString = '' + number;
  while (myString.length < length) {
    myString = '0' + myString;
  }

  return myString;

}