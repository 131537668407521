import Reservation from '../Reservation';
import Banner from './Banner';
import HomeBlog from './HomeBlog';
import Offer from './Offer';

export default function Home() {
  return (
    <>
      <Banner />
      <Offer />
      <Reservation />
      <HomeBlog />
    </>
  );
}
