import {BrowserRouter} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import Footer from './components/Footer';
import NavBar from './components/NavBar';
import Root from './components/Root';
import TopBar from './components/TopBar';
import {GlobalContextProvider} from './context/globalContext';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <GlobalContextProvider>
          <TopBar />
          <NavBar />
          <Root />
          <Footer />
        </GlobalContextProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
