import {Link} from 'react-router-dom';
import {useGlobal} from '../context/globalContext';

export default function Breadcrumb({name}) {
  const {trans} = useGlobal();
  return (
    <section className="tf__breadcrumb" style={{background: 'url(images/counter_bg.jpg)'}}>
      <div className="tf__breadcrumb_overlay">
        <div className="container">
          <div className="tf__breadcrumb_text">
            <h1>{name}</h1>
            <ul>
              <li>
                <Link to="/">{trans('home')}</Link>
              </li>
              <li>
                <Link to={location.pathname}>{name}</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
