import {FormEvent, useState} from 'react';
import {toast} from 'react-toastify';
import {useGlobal} from '../context/globalContext';
import api from '../utils/api';

export default function Reservation() {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [person, setPerson] = useState(0);
  const {trans} = useGlobal();

  const isReady = name !== '' && phone !== '' && email !== '' && time !== '' && person !== 0;

  const save = async (e: FormEvent) => {
    e.preventDefault();
    const body = {name, email, phone, date, time, person};
    await api.post({path: 'web/reservation', body});
    toast.success('Make reservation success');
    setName('');
    setEmail('');
    setPhone('');
    setDate('');
    setPerson(0);
    setTime('');
  };

  return (
    <section className="tf__reservation mt_100 xs_mt_70">
      <div className="container">
        <div className="tf__reservation_bg" style={{background: `url(images/reservation_bg.jpg)`}}>
          <div className="row">
            <div className="col-xl-6 ms-auto">
              <div className="tf__reservation_form wow fadeInRight" data-wow-duration="1s">
                <h2>{trans('BOOK_A_TABLE')}</h2>
                <form onSubmit={save}>
                  <div className="row">
                    <div className="col-xl-6 col-lg-6">
                      <div className="tf__reservation_input_single">
                        <label htmlFor="name">{trans('NAME')}</label>
                        <input
                          type="text"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          id="name"
                          placeholder={trans('NAME')}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6">
                      <div className="tf__reservation_input_single">
                        <label htmlFor="email">{trans('email')}</label>
                        <input
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          type="email"
                          id="email"
                          placeholder={trans('email')}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6">
                      <div className="tf__reservation_input_single">
                        <label htmlFor="phone">{trans('PHONE')}</label>
                        <input
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          type="text"
                          id="phone"
                          placeholder={trans('PHONE')}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6">
                      <div className="tf__reservation_input_single">
                        <label htmlFor="date">{trans('SELECT_DATE')}</label>
                        <input value={date} onChange={(e) => setDate(e.target.value)} type="date" id="date" />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6">
                      <div className="tf__reservation_input_single">
                        <label>{trans('SELECT_TIME')}</label>
                        <input
                          type="time"
                          value={time}
                          onChange={(e) => {
                            setTime(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6">
                      <div className="tf__reservation_input_single">
                        <label>{trans('SELECT_PERSON')}</label>
                        <select
                          value={person}
                          onChange={(e) => setPerson(parseInt(e.target.value))}
                          className="reservation_input select_js"
                        >
                          <option value={0}>{trans('SELECT')}</option>
                          <option value={1}>1 {trans('PERSON')}</option>
                          <option value={2}>2 {trans('PERSON')}</option>
                          <option value={3}>3 {trans('PERSON')}</option>
                          <option value={4}>4 {trans('PERSON')}</option>
                          <option value={5}>5 {trans('PERSON')}</option>
                          <option value={6}>{trans('MORE_THAN_5_PERSON')}</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <button disabled={!isReady} type="submit" className="common_btn">
                        {trans('CONFIRM')}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
