import {useGlobal} from '../context/globalContext';
import Breadcrumb from './Breadcrumb';

export default function AboutUs() {
  const {translation, trans} = useGlobal();
  return (
    <>
      <Breadcrumb name={trans('About us')} />

      <section className="tf__terms_condition mt_90 xs_mt_60 mb_100 xs_mb_70">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 wow fadeInUp" data-wow-duration="1s">
              <div className="tf__career_det_text" dangerouslySetInnerHTML={{__html: translation.about}} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
