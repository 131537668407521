import classNames from 'class-names';
import Flag from 'react-flagkit';
import {Link, NavLink} from 'react-router-dom';
import {useGlobal} from '../context/globalContext';
import {LanguageCode} from '../context/translate';

export default function NavBar() {
  const {language, languages, setLanguage, trans} = useGlobal();

  const Links: Array<{text: LanguageCode; url: string}> = [
    {text: 'HOME', url: '/'},
    {text: 'ABOUT_US', url: '/about-us'},
    {text: 'RESERVATION', url: '/yoyaku.html'},
    // {text: 'MENU', url: '/menu'},
    {text: 'NEWS', url: '/news'},
    {text: 'CONTACT', url: '/contact'}
  ];

  return (
    <nav className="navbar navbar-expand-lg main_menu">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img src="images/logo.svg" alt="RegFood" className="img-fluid" />
        </Link>

        <div className="flex gap-1 language mobile-only">
          {languages.map((lang) => (
            <div
              onClick={() => setLanguage(lang.code)}
              className={classNames('', {
                'clickable opacity-60 grayscale': lang.code !== language
              })}
              key={lang.id}
            >
              <Flag country={lang.code} size={40} style={{height: 40, width: 120}} />
            </div>
          ))}
        </div>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="fa fa-bars menu_icon_bar"></i>
          <i className="fa fa-times close_icon_close"></i>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav m-auto">
            {Links.map((link) => {
              return (
                <li key={link.url} className="nav-item">
                  <NavLink className={`nav-link`} exact activeClassName="active" aria-current="page" to={link.url}>
                    {trans(link.text)}
                  </NavLink>
                </li>
              );
            })}
          </ul>
          <div className="flex gap-1 language desktop-only">
            {languages.map((lang) => (
              <div
                onClick={() => setLanguage(lang.code)}
                className={classNames('', {
                  'clickable opacity-60 grayscale': lang.code !== language
                })}
                key={lang.id}
              >
                <Flag country={lang.code} size={40} style={{height: 40, width: 120}} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </nav>
  );
}
