import {useGlobal} from '../context/globalContext';
import Breadcrumb from './Breadcrumb';
import Reservation from './Reservation';

export default function ReservationPage() {
  const {trans} = useGlobal();
  return (
    <>
      <Breadcrumb name={trans('RESERVATION')} />
      <Reservation />
      <div className="pb-lg-5" />
      <div className="pb-lg-5" />
      <div className="pb-lg-5" />
    </>
  );
}
