/* exlint-disable */

import {LANGUAGE_KEY} from '@betofumi/common/config';
import {Language, WebInfo, WebTranslationDetail, WebSlideshow} from '@betofumi/common/types/dataTypes';
import {createContext, Dispatch, ReactNode, SetStateAction, useContext, useState} from 'react';
import {useEffectOnce} from 'react-use';
import api from '../utils/api';
import translate, {Languages} from './translate';

export type GlobalContextType = {
  language: Languages;
  setLanguage: Dispatch<SetStateAction<Languages>>;
  languages: Language[];
  info: InfoType;
};
export const GlobalContext = createContext<GlobalContextType>({
  language: 'JP',
  setLanguage: () => undefined,
  languages: [],
  info: undefined
});

type InfoType = (WebInfo & {translation: WebTranslationDetail[]; slides: WebSlideshow[]}) | undefined;

export const GlobalContextProvider = ({children}: {children: ReactNode}): JSX.Element => {
  const [language, setLanguage] = useState<Languages>('JP');
  const [languages, setLanguages] = useState<Language[]>([]);
  const [info, setInfo] = useState<InfoType>();

  useEffectOnce(() => {
    const currentLanguage = localStorage.getItem(LANGUAGE_KEY) as Languages;
    if (currentLanguage == null) {
      setLanguage('JP');
    } else {
      setLanguage(currentLanguage);
    }

    api.get<Language[]>({path: 'language'}).then((languages) => setLanguages(languages));
    api.get<InfoType>({path: 'web'}).then((info) => {
      setInfo(info);
    });
  });

  return <GlobalContext.Provider value={{language, setLanguage, languages, info}}>{children}</GlobalContext.Provider>;
};

export function useGlobal() {
  const context = useContext(GlobalContext);
  const {language, info, languages} = context;

  const languageId = languages.find((l) => l.code === language)?.id;

  const translation = (info?.translation.find((t) => t.code === (language as string)) ||
    {}) as Partial<WebTranslationDetail>;
  const currentSlides = info?.slides.filter((s) => s.languageId === languageId) as WebSlideshow[];

  const setLanguage = (language: Languages) => {
    context.setLanguage(language);
    localStorage.setItem(LANGUAGE_KEY, language);
  };

  const trans = (code: string) => {
    const key = code.replaceAll(' ', '_').toUpperCase();
    return translate(language, key);
  };

  return {trans, ...context, setLanguage, translation: translation, slides: currentSlides || [], languageId};
}
