import {Helmet} from 'react-helmet';
import {Route, Switch} from 'react-router-dom';
import {useGlobal} from '../context/globalContext';
import AboutUs from './AboutUs';
import Contact from './Contact';
import Menu from './Menu';
import News from './News';
import NewsDetails from './NewsDetails';
import ReservationPage from './ReservationPage';
import Home from './home/Home';

export default function Root() {
  const {translation} = useGlobal();

  return (
    <>
      <Helmet>
        <title>{translation?.title || ''}</title>
        <meta name="description" content={translation?.description || ''} />
      </Helmet>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/menu" component={Menu} />
        <Route exact path="/news" component={News} />
        <Route exact path="/news/:slug" component={NewsDetails} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/about-us" component={AboutUs} />
        <Route exact path="/yoyaku.html" component={ReservationPage} />
      </Switch>
    </>
  );
}
