export type Languages = 'VN' | 'GB' | 'JP';
type DataType = Record<string, Record<Languages, string>>;

const data: DataType = {
  HOME: {
    VN: 'Trang chủ',
    GB: 'Home',
    JP: 'ホームページ'
  },
  ABOUT_US: {
    VN: 'Về chúng tôi',
    GB: 'About Us',
    JP: '会社概要'
  },
  RESERVATION: {
    VN: 'Đặt chỗ',
    GB: 'Reservation',
    JP: '予約'
  },
  MENU: {
    VN: 'Thực đơn',
    GB: 'Menu',
    JP: 'メニュー'
  },
  NEWS: {
    VN: 'Tin tức',
    GB: 'News',
    JP: 'ニュース'
  },
  CONTACT: {
    VN: 'Liên hệ',
    GB: 'Contact',
    JP: 'お問い合わせ'
  },
  TERMS_CONDITIONS: {
    VN: 'Điều khoản và Điều kiện',
    GB: 'Terms & Conditions',
    JP: '利用規約'
  },
  PRIVACY_POLICY: {
    VN: 'Chính sách bảo mật',
    GB: 'Privacy Policy',
    JP: 'プライバシーポリシー'
  },
  FAQ: {
    VN: 'Câu hỏi thường gặp',
    GB: 'FAQ',
    JP: 'よくある質問'
  },
  SHORT_LINKS: {
    VN: 'Liên kết nhanh',
    GB: 'Short Links',
    JP: '短縮リンク'
  },
  HELP_LINKS: {
    VN: 'Liên kết trợ giúp',
    GB: 'Help Links',
    JP: 'ヘルプリンク'
  },
  CONTACT_US: {
    VN: 'Liên hệ với chúng tôi',
    GB: 'Contact Us',
    JP: 'お問い合わせ'
  },
  BOOK_A_TABLE: {
    VN: 'Đặt bàn',
    GB: 'Book A Table',
    JP: 'テーブル予約'
  },
  NAME: {
    VN: 'Họ và tên',
    GB: 'Name',
    JP: '名前'
  },
  PHONE: {
    VN: 'Điện thoại',
    GB: 'Phone',
    JP: '電話番号'
  },
  SELECT_DATE: {
    VN: 'Chọn ngày',
    GB: 'Select Date',
    JP: '日付を選択'
  },
  SELECT_TIME: {
    VN: 'Chọn giờ',
    GB: 'Select Time',
    JP: '時間を選択'
  },
  SELECT_PERSON: {
    VN: 'Chọn số người',
    GB: 'Select Person',
    JP: '人数を選択'
  },
  CONFIRM: {
    VN: 'Xác nhận',
    GB: 'Confirm',
    JP: '確認'
  },
  CALL: {
    VN: 'Gọi',
    GB: 'Call',
    JP: '電話する'
  },
  LOCATION: {
    VN: 'Địa điểm',
    GB: 'Location',
    JP: '場所'
  },
  SEND_MESSAGE: {
    VN: 'Gửi tin nhắn',
    GB: 'Send Message',
    JP: 'メッセージを送信'
  },
  SELECT: {
    VN: 'Chọn',
    GB: 'Select',
    JP: '選択'
  },
  PERSON: {
    VN: 'Người',
    GB: 'Person',
    JP: '人'
  },
  MORE_THAN_5_PERSON: {
    VN: 'Hơn 5 người',
    GB: 'More than 5 person',
    JP: '5人以上'
  },
  EMAIL: {
    VN: 'Email',
    GB: 'Email',
    JP: 'メール'
  },
  SUBJECT: {
    VN: 'Chủ đề',
    GB: 'Subject',
    JP: '件名'
  },
  MESSAGE: {
    VN: 'Nội dung',
    GB: 'Message',
    JP: 'メッセージ'
  },
  FEATURE_DISHES: {
    VN: 'Món ăn nổi bật',
    GB: 'Feature Dishes',
    JP: '特製料理'
  },
  NEWS_BLOGS: {
    VN: 'Tin tức và Blog',
    GB: 'News & Blogs',
    JP: 'ニュース＆ブログ'
  },
  LATEST_FOODS_NEWS: {
    VN: 'Thông tin ẩm thực mới nhất',
    GB: 'Our Latest Foods News',
    JP: '最新のフードニュース'
  },
  READ_MORE: {
    VN: 'Đọc thêm',
    GB: 'Read More',
    JP: 'もっと読む'
  },
  COPYRIGHT: {
    VN: 'Bản quyền © Betofumi 2023. Đã đăng ký.  ',
    GB: 'Copyright © Betofumi 2023. All Rights Reserved.',
    JP: '著作権 © Betofumi 2023. 全著作権所有。'
  },
  POPULAR_DELICIOUS_FOODS: {
    VN: 'Món ngon phổ biến',
    GB: 'Popular Delicious Foods',
    JP: '人気のおいしい料理'
  },
  FEATURE_FOODS_MENU: {
    VN: 'Thực đơn món ăn nổi bật',
    GB: 'Feature Foods Menu',
    JP: '特製料理メニュー'
  },
  LATEST_POST: {
    VN: 'Bài viết mới nhất',
    GB: 'Latest Post',
    JP: '最新記事'
  },
  CATEGORIES: {
    VN: 'Danh mục',
    GB: 'Categories',
    JP: 'カテゴリー'
  },
  POPULAR_TAGS: {
    VN: 'Thẻ phổ biến',
    GB: 'Popular Tags',
    JP: '人気のタグ'
  },
  LEAVE_A_COMMENT: {
    VN: 'Để lại bình luận',
    GB: 'Leave a Comment',
    JP: 'コメントを残す'
  },
  EMAIL_ADDRESS_NOT_PUBLISHED: {
    VN: 'Địa chỉ email của bạn sẽ không được công bố. Các trường bắt buộc đã được đánh dấu.',
    GB: 'Your email address will not be published. Required fields are marked.',
    JP: 'メールアドレスは公開されません。必須フィールドはマークされています。'
  },
  COMMENT: {
    VN: 'Bình luận',
    GB: 'Comment',
    JP: 'コメント'
  },
  YOUR_COMMENT: {
    VN: 'Bình luận của bạn',
    GB: 'Your comment',
    JP: 'あなたのコメント'
  },
  SUBMIT_COMMENT: {
    VN: 'Gửi bình luận',
    GB: 'Submit comment',
    JP: 'コメントを送信する'
  },
  SHARE: {
    VN: 'Chia sẻ',
    GB: 'Share',
    JP: 'シェア'
  }
};

// const LANGUAGES = Object.keys(data) as const;
export type LanguageCode = keyof typeof data;

export default function translate(language: Languages, code: LanguageCode) {
  return data[code][language];
}
