import {ChangeEvent, FormEvent, useState} from 'react';
import {toast} from 'react-toastify';
import {useGlobal} from '../context/globalContext';
import api from '../utils/api';
import Breadcrumb from './Breadcrumb';

interface ContactFormData {
  name: string;
  email: string;
  phone: string;
  subject: string;
  message: string;
}

const defaultData: ContactFormData = {name: '', email: '', message: '', phone: '', subject: ''};
export default function Contact() {
  const {info, language, trans} = useGlobal();
  const [formData, setFormData] = useState<ContactFormData>(defaultData);

  const {name, email, phone, subject, message} = formData;

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await api.post({path: 'web/contact', body: formData});
    setFormData(defaultData);
    toast.success('Send contact successfully');
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {name, value} = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  return (
    <>
      <Breadcrumb name={trans('Contact')} />
      <section className="tf__contact mt_100 xs_mt_70 mb_100 xs_mb_70">
        <div className="container">
          <div className="tf__contact_form_area">
            <div className="row">
              <div className="col-xl-5 col-md-6 col-lg-5 wow fadeInUp" data-wow-duration="1s">
                <div className="tf__contact_info_area">
                  <div className="tf__contact_info">
                    <h3>{trans('call')}</h3>
                    <p>{info?.phone}</p>
                  </div>
                  <div className="tf__contact_info">
                    <h3>{trans('email')}</h3>
                    <p>{info?.email}</p>
                  </div>
                  <div className="tf__contact_info border-0 p-0 m-0">
                    <h3>{trans('location')}</h3>
                    <p>{info?.translation.find((t) => t.code === (language as string))?.address}</p>
                  </div>
                </div>
              </div>

              <div className="col-xl-7 col-md-6 col-lg-7 wow fadeInUp" data-wow-duration="1s">
                <form className="tf__contact_form" onSubmit={handleFormSubmit}>
                  <h3>{trans('contact us')}</h3>
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="tf__contact_form_input">
                        <span>
                          <i className="fas fa-user"></i>
                        </span>
                        <input
                          type="text"
                          name="name"
                          placeholder={trans('name')}
                          value={name}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="tf__contact_form_input">
                        <span>
                          <i className="fas fa-envelope"></i>
                        </span>
                        <input
                          type="email"
                          name="email"
                          placeholder={trans('email')}
                          value={email}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="tf__contact_form_input">
                        <span>
                          <i className="fas fa-phone-alt"></i>
                        </span>
                        <input
                          type="text"
                          name="phone"
                          placeholder={trans('phone')}
                          value={phone}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="tf__contact_form_input">
                        <span>
                          <i className="fas fa-book"></i>
                        </span>
                        <input
                          type="text"
                          name="subject"
                          placeholder={trans('Subject')}
                          value={subject}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="tf__contact_form_input textarea">
                        <span>
                          <i className="fas fa-pen"></i>
                        </span>
                        <textarea
                          name="message"
                          placeholder={trans('message')}
                          value={message}
                          onChange={handleInputChange}
                        />
                      </div>
                      <button
                        className="common_btn"
                        type="submit"
                        disabled={Object.values(formData).find((i) => i === '') !== undefined}
                      >
                        {trans('send message')}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="tf__contact_map_area">
            <div className="row mt_100 xs_mt_70">
              <div className="col-12 wow fadeInUp" data-wow-duration="1s">
                <div className="tf__contact_map">
                  <iframe
                    src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJgb_a2utN-F8RHhVLqaRYPQA&key=AIzaSyBTkCyXMWQe_92UqtqyO0_FzRiPJFMBzDQ"
                    style={{border: 0}}
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
