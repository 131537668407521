import {WebBlog} from '@betofumi/common/types/dataTypes';
import moment from 'moment';
import {HTMLProps, useState} from 'react';
import Slider from 'react-slick';
import {useAsyncEffect} from 'use-async-effect';
import {useGlobal} from '../../context/globalContext';
import api from '../../utils/api';

type Props = HTMLProps<never>;
export function SampleNextArrow(props: Props) {
  const {className, style, onClick} = props;
  return <i className={`${className} fa fa-arrow-right nextArrow`} style={style} onClick={onClick} />;
}

export function SamplePrevArrow(props: Props) {
  const {className, style, onClick} = props;
  return <i className={`${className} fa fa-arrow-left prevArrow`} style={style} onClick={onClick} />;
}

export const NewsItem = ({item, home = true}: {item: WebBlog; home?: boolean}) => {
  const {trans} = useGlobal();
  return (
    <div key={item.id} className={`wow fadeInUp ${home ? '' : 'col-xl-4 col-md-6'}`} data-wow-duration="1s">
      <div className="tf__single_blog">
        <div className="tf__single_blog_img">
          <img src={item.coverUrl} alt="author" className="img-fluid w-100" />
        </div>
        <div className="tf__single_blog_author">
          <div className="img">
            <img src="images/logo.svg" alt="author" className="img-fluid w-100" />
          </div>
          <div className="text">
            <h5>Betofumi</h5>
            <p>{moment(item.createdDate).format('DD-MMMM-yyyy')}</p>
          </div>
        </div>
        <div className="tf__single_blog_text">
          <span className="category">{item.tag}</span>
          <a className="title" href={`/news/${item.slug}`}>
            {item.title}
          </a>
          <p>{item.description}</p>
          <div className="tf__single_blog_footer">
            <a className="read_btn" href={`/news/${item.slug}`}>
              {trans('Read_more')} <i className="far fa-long-arrow-right"></i>
            </a>
            {/*<span>*/}
            {/*  <i className="far fa-comments"></i> 120*/}
            {/*</span>*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default function HomeBlog() {
  const {trans} = useGlobal();
  const [news, setNews] = useState<WebBlog[]>([]);

  useAsyncEffect(async () => {
    const news = await api.get<WebBlog[]>({path: 'web/news'});
    setNews(news);
  }, []);

  const options = {
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 4000,
    dots: false,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 576,
        settings: {
          arrows: false,
          slidesToShow: 1
        }
      }
    ]
  };
  return (
    <section className="tf__blog pt_95 xs_pt_65 pb_65 xs_pb_35">
      <div className="container">
        <div className="row wow fadeInUp" data-wow-duration="1s">
          <div className="col-md-8 col-lg-7 col-xl-6">
            <div className="tf__section_heading mb_25">
              <h4>{trans('NEWS_BLOGS')}</h4>
              <h2>{trans('LATEST_FOODS_NEWS')}</h2>
            </div>
          </div>
        </div>
        <div className="row blog_slider">
          <Slider {...options}>
            {news.map((item) => (
              <NewsItem key={item.id} item={item} />
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
}
