import {WebBlog} from '@betofumi/common/types/dataTypes';
import moment from 'moment/moment';
import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {useAsyncEffect} from 'use-async-effect';
import {useGlobal} from '../context/globalContext';
import api from '../utils/api';
import Breadcrumb from './Breadcrumb';

export default function NewsDetails() {
  const [item, setItem] = useState<WebBlog | undefined>();
  const [news, setNews] = useState<WebBlog[]>([]);
  const {trans} = useGlobal();
  const {slug} = useParams<{slug: string}>();

  useAsyncEffect(async () => {
    const item = await api.get<WebBlog>({path: 'web/news/' + slug});
    setItem(item);

    const news = await api.get<WebBlog[]>({path: 'web/news'});
    setNews(news);
  }, []);

  if (item == null) {
    return null;
  }

  return (
    <>
      <Breadcrumb name={item.title}></Breadcrumb>
      <section className="tf__blog_details mt_100 xs_mt_70 mb_100 xs_mb_70">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-8">
              <div className="tf__blog_det_area">
                <div className="tf__blog_details_img wow fadeInUp" data-wow-duration="1s">
                  <img src={item.coverUrl} alt="blog details" className="imf-fluid w-100" />
                </div>
                <div className="tf__blog_details_text wow fadeInUp" data-wow-duration="1s">
                  <ul className="details_bloger d-flex flex-wrap">
                    <li>
                      <i className="far fa-user"></i> By Betofumi
                    </li>
                    <li>
                      <i className="far fa-calendar-alt"></i> {moment(item.createdDate).format('DD-MMMM-yyyy')}
                    </li>
                  </ul>

                  <div dangerouslySetInnerHTML={{__html: item.content}} />

                  <div className="blog_tags_share d-flex flex-wrap justify-content-between align-items-center">
                    <div className="tags d-flex flex-wrap align-items-center">
                      <span>tags:</span>
                      <ul className="d-flex flex-wrap">
                        <li>
                          <a href="#">{item.tag}</a>
                        </li>
                      </ul>
                    </div>
                    <div className="share d-flex flex-wrap align-items-center">
                      <span>{trans('share')}:</span>
                      <ul className="d-flex flex-wrap">
                        <li>
                          <a href="#">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-linkedin-in"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-pinterest-p"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="comment_input mt_50 wow fadeInUp" data-wow-duration="1s">
                <h4>{trans('LEAVE_A_COMMENT')}</h4>
                <p>{trans('EMAIL_ADDRESS_NOT_PUBLISHED')} *</p>
                <form>
                  <div className="row">
                    <div className="col-xl-6 col-md-6">
                      <label>{trans('name')}</label>
                      <div className="tf__contact_form_input">
                        <span>
                          <i className="fas fa-user"></i>
                        </span>
                        <input type="text" placeholder={trans('name')} />
                      </div>
                    </div>
                    <div className="col-xl-6 col-md-6">
                      <label>{trans('email')}</label>
                      <div className="tf__contact_form_input">
                        <span>
                          <i className="fas fa-envelope"></i>
                        </span>
                        <input type="email" placeholder={trans('email')} />
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <label>{trans('comment')}</label>
                      <div className="tf__contact_form_input textarea">
                        <span>
                          <i className="fas fa-pen"></i>
                        </span>
                        <textarea rows="5" placeholder={trans('Your Comment')}></textarea>
                      </div>
                      <button type="submit" className="common_btn mt_20">
                        {trans('Submit comment')}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4">
              <div id="sticky_sidebar">
                <div
                  className="tf__related_blog blog_sidebar wow fadeInUp"
                  style={{marginTop: 0}}
                  data-wow-duration="1s"
                >
                  <h3>{trans('Latest Post')}</h3>
                  <ul>
                    {news
                      .filter((post) => post.slug !== slug)
                      .map((post) => {
                        return (
                          <li key={post.id}>
                            <img src={post.coverUrl} alt="blog" className="img-fluid w-100" />
                            <div className="text">
                              <a href={`/news/${post.slug}`}>{post.title}</a>
                              <p>
                                <i className="far fa-calendar-alt"></i>{' '}
                                {moment(post.createdDate).format('DD-MMMM-yyyy')}
                              </p>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </div>
                <div className="tf__blog_tags blog_sidebar wow fadeInUp" data-wow-duration="1s">
                  <h3>{trans('Popular Tags')}</h3>
                  <ul>
                    <li>
                      <a href="#">Ẩm thực</a>
                    </li>
                    <li>
                      <a href="#">Tin tức</a>
                    </li>
                    <li>
                      <a href="#">Nấu ăn</a>
                    </li>
                    <li>
                      <a href="#">Phở</a>
                    </li>
                    <li>
                      <a href="#">Nhà hàng</a>
                    </li>
                    <li>
                      <a href="#">Thực phẩm</a>
                    </li>
                    <li>
                      <a href="#">Nguyên liệu</a>
                    </li>
                    <li>
                      <a href="#">Việt Nam</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
